import { GetServerSideProps, NextPage } from "next";
import Logo from "lib/components/Logo";
import { withPageAuth, withUserAuth } from "lib/server/withAuth";
import LandingPage from "lib/components/LandingPage";

const Page: NextPage = () => {
  return (
    <LandingPage
      title="bpfdeploy.io - Your eBPF Platform"
      description="Visualize, monitor and debug your eBPF infrastructure from a single viewpoint"
    >
      <div className="mx-auto max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:pb-32">
        <section className="pt-24 md:max-w-4xl md:mx-auto">
          <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
            Coming soon
          </span>
          <h1 className="mt-1 tracking-tight font-normal text-4xl text-white sm:text-7xl">
            Visualize, monitor and debug your{" "}
            <span className="text-primary-700 font-semibold">
              eBPF infrastructure
            </span>{" "}
            from a single viewpoint
          </h1>
        </section>
        <section className="pt-24 lg:pt-36">
          <div className="p-4 bg-gradient-to-r from-primary-300 to-primary-100 text-white rounded-md md:pt-8 md:pb-16">
            <div className="md:max-w-2xl md:mx-auto">
              <Logo small />
              <h2 className="pt-2 md:pt-4">
                <span
                  className="text-2xl font-medium md:text-4xl"
                  data-test="heading"
                >
                  Why bpfdeploy.io?
                </span>
              </h2>
              <p className="mt-4 text-lg md:pt-4 md:text-2xl">
                eBPF (extended BPF) is a new in-kernel technology originating
                from Linux that allows us to dynamically introspect our runtimes
                in a safe and event-driven way. This has lead to innovations in
                networking, security, observability and even CPU scheduling. It
                is used by companies such as Facebook, Cloudflare, Netflix and
                Google.
              </p>
              <p className="mt-4 text-lg md:pt-4 md:text-2xl">
                We are still in the early stages of eBPF and although progress
                in the technology has been swift, the developer experience is
                still full of friction. With such a powerful technology, we
                believe that more work needs to be done to bring eBPF to the
                masses.
              </p>
              <p className="mt-4 text-lg md:pt-4 md:text-2xl">
                eBPF is a technology that asks developers to straddle both user
                and kernel space throughout the application lifecycle. This
                requires a shift in thinking and without proper tooling can lead
                to a frustrating experience.
              </p>
              <p className="mt-4 text-lg md:pt-4 md:text-2xl">
                The vision of bpfdeploy.io is focused on creating frictionless
                tooling that allows developers of distinct ecosystems to reap
                the benefits of eBPF. We believe that if we want software
                organizations to succeed, we also need to provide secure turnkey
                solutions towards monitoring and auditing their eBPF
                infrastructure.
              </p>
              <p className="mt-4 text-lg md:pt-4 md:text-2xl">
                bpfdeploy.io allows developers to feel confident reaching into
                their underlying runtimes.
              </p>
            </div>
          </div>
        </section>
      </div>
    </LandingPage>
  );
};

export default withPageAuth(Page);

export const getServerSideProps: GetServerSideProps = withUserAuth;
